import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import {
  CardProductPresentation,
  ParticipantRoom,
  Tag,
  WindowResource
} from 'atomic-lib';
import { Observable, Subject, combineLatest, filter, of } from 'rxjs';
import { catchError, debounceTime, switchMap, tap } from 'rxjs/operators';
import { TriggerAlert, TriggerLoading } from '../../../app.action';
import { CartContentComponent } from '../../../cart/cart-content/cart-content.component';
import {
  AddAccommodationToCart,
  ChangeCartDrawerState
} from '../../../cart/cart.action';
import { CartState } from '../../../cart/cart.state';
import { FiltersState } from '../../../filters.state';
import { AccommodationService } from '../../../service/accommodation.service';
import { ExperienceService } from '../../../service/experience.service';
import { MetaDescriptionService } from '../../../service/meta-description.service';
import { MapComponent } from '../../../shared/component/map.component';
import { AccommodationPartner } from '../../../shared/models/accommodation/accommodation-partner';
import { Establishment } from '../../../shared/models/accommodation/establishment';
import { ItemAccommodation } from '../../../shared/models/accommodation/item-accommodation';
import { ProposalQuery } from '../../../shared/models/accommodation/proposal-query';
import { Room } from '../../../shared/models/accommodation/room';
import { RoomProposal } from '../../../shared/models/accommodation/room-proposal';
import { Alert } from '../../../shared/models/alert';
import { Cart } from '../../../shared/models/cart/cart';
import { Criteria } from '../../../shared/models/criteria';
import { ExperienceCategoryEnum } from '../../../shared/models/enum/experience-category.enum';
import { Origin } from '../../../shared/models/enum/origin.enum';
import { MarkerWrapper } from '../../../shared/models/marker-wrapper';
import { Participant } from '../../../shared/models/participant/participant';
import { Period } from '../../../shared/models/period';
import { Session } from '../../../shared/models/session/session';
import { AccommodationUtils } from '../../../utils/accommodation-utils';
import { PackageUtils } from '../../../utils/package-utils';
import { UrlUtils } from '../../../utils/url-utils';
import { SetEstablishment } from '../accommodation.action';

export interface ProposalClick {
  participantsUuid: string[];
  room: Room;
  criteria: Criteria;
}

export interface BreadcrumbLink {
  label: string;
  url: string;
}

export interface Feature {
  label: string;
  icon: string;
}

@Component({
  selector: 'vsk-resort-establishment',
  templateUrl: './resort-establishment.component.html',
  styleUrls: ['./resort-establishment.component.scss']
})
export class ResortEstablishmentComponent
  extends MapComponent<Establishment>
  implements OnDestroy
{
  @Select(CartState.cart) cart$: Observable<Cart>;
  @Select(FiltersState.criteria) criteria$: Observable<Criteria>;
  @Select(FiltersState.participants) participants$: Observable<Participant[]>;

  public readonly mapItemsParticipants =
    CartContentComponent.mapItemsParticipants;

  roomCodeObs$: Subject<string> = new Subject<string>();
  establishment: Establishment;
  roomCode: string;
  room?: Room;
  rooms: Room[] = [];
  participants: ParticipantRoom[] = [];
  showPhotoEstablishmentPopup = false;
  showUpsell = false;
  packages: CardProductPresentation[] = [];
  experiences: CardProductPresentation[] = [];
  roomPictures: string[] = [];
  currentSlide = 0;
  roomQuery: Room;
  error = '';
  success = '';
  showAlert = false;
  bookedRoom = false;
  loading = false;
  period: Period;
  noProposals = false;
  clickDebounce = new Subject<ProposalClick>();
  roomsCarousel: CardProductPresentation[] = [];
  numberOfNights: number;
  breadcrumbResort: BreadcrumbLink;
  breadcrumbItems: BreadcrumbLink[] = [];

  constructor(
    private accommodationService: AccommodationService,
    private experienceService: ExperienceService,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    public windowResource: WindowResource,
    private metaDescriptionService: MetaDescriptionService,
    public router: Router
  ) {
    super(windowResource, router);
    this.focusOnPinpoint = false;

    combineLatest([
      this.clickDebounce.asObservable(),
      this.criteria$.pipe(filter((criteria) => criteria.isValid))
    ])
      .pipe(
        tap(() => {
          this.roomQuery.proposals = [];
          this.loading = true;
        }),
        debounceTime(1000),
        switchMap(([proposal]) => {
          const query = new ProposalQuery({
            establishmentId: this.establishment.id,
            participants: proposal.participantsUuid,
            partnerCode: this.establishment.partnerCode,
            roomCode: proposal.room.codeRoom,
            criteria: proposal.criteria
          });

          return this.accommodationService.getProposalsForRoom(query);
        })
      )
      .subscribe((proposals) => {
        this.roomQuery.proposals = proposals;
        this.loading = false;
      });

    this.register(
      combineLatest([
        this.activatedRoute.queryParamMap,
        this.criteria$.pipe(filter((criteria) => criteria.isValid))
      ])
        .pipe(
          debounceTime(500),
          tap(() => {
            this.noProposals = false;
            this.rooms = [];
          }),
          switchMap(([params, criteria]) => {
            this.period = new Period({
              startDate: criteria.startDate,
              endDate: criteria.endDate
            });
            this.roomCode = params.get('roomCode') as string;
            this.roomCodeObs$.next(this.roomCode);
            this.numberOfNights = this.period.endDate.diff(
              this.period.startDate,
              'days'
            );

            this.breadcrumbResort = {
              label: criteria.stationName,
              url: `station/${UrlUtils.encodeToURL(criteria.stationName)}/informations`
            };

            return this.accommodationService.getRoomsByCriteria(
              criteria,
              Number(params.get('establishmentId')),
              String(params.get('partnerCode'))
            );
          })
        )
        .subscribe((rooms) => {
          this.room = rooms.find((room) => room.codeRoom === this.roomCode);
          this.rooms = rooms.filter((room) => room.codeRoom !== this.roomCode);
          this.participants.forEach((participant, i) => {
            if (this.room) {
              participant.selected = i < this.room.maxPeople;
            }
          });

          this.breadcrumbItems = [
            this.breadcrumbResort,
            {
              label: 'Hébergements',
              url: `station/${UrlUtils.encodeToURL(this.breadcrumbResort.label)}/hebergements`
            },
            { label: this.establishment.name, url: '' }
          ];

          this.mapRoomsCarousel();
          this.noProposals = !this.room;
          this.store.dispatch(new TriggerLoading(false));
          this.updateTitleAndMetaDescription(this.establishment, this.room);
        })
    );

    this.register(
      this.activatedRoute.queryParamMap
        .pipe(
          tap(() => this.store.dispatch(new TriggerLoading(true))),
          switchMap((params) =>
            combineLatest([
              this.accommodationService.getEstablishmentByPartnerCodeAndId(
                Number(params.get('establishmentId')),
                String(params.get('partnerCode'))
              ),
              this.participants$
            ])
          ),
          filter(([establishment]) => !!establishment)
        )
        .subscribe(([establishment, participants]) => {
          this.establishment = establishment;
          this.establishment.marker = new MarkerWrapper<Establishment>(
            establishment,
            {
              clickable: true,
              position: {
                lat: establishment.location.lat,
                lng: establishment.location.lon
              },
              icon: {
                url: '../../../../assets/svg/pinpoints/accommodation-circle.svg',
                scaledSize: new google.maps.Size(30, 30, 'px', 'px'),
                origin: new google.maps.Point(0, 0)
              },
              title: establishment.name + ' - ' + establishment.partnerCode,
              optimized: false,
              zIndex: 100
            }
          );

          this.markers = [this.establishment.marker];
          this.store.dispatch(new SetEstablishment(establishment));
          this.participants = participants
            .sort((prev, curr) => (prev.index < curr.index ? -1 : 1))
            .map((participant) => {
              const ageRange = this.establishment.ages.find(
                (age) =>
                  age.start <= participant.age && age.end >= participant.age
              );
              return {
                uuid: participant.uuid,
                name: participant.firstname,
                ageRangeName: ageRange?.label,
                price: ageRange?.reduction,
                selected: false
              } as ParticipantRoom;
            });
          this.center = {
            lat: this.establishment.location.lat,
            lng: this.establishment.location.lon
          };
          this.store.dispatch(new TriggerLoading(false));
        })
    );

    this.register(
      combineLatest([
        this.roomCodeObs$,
        this.cart$.pipe(filter((cart) => cart.itemsAccommodation?.length > 0))
      ]).subscribe(([roomCode, cart]) => {
        this.bookedRoom =
          cart.itemsAccommodation.filter(
            (item) => item.room.codeRoom === roomCode
          ).length > 0;
      })
    );

    // Upsell experiences
    this.register(
      this.criteria$
        .pipe(
          filter((criteria) => criteria.isValid),
          switchMap((criteria) =>
            combineLatest([
              this.experienceService.getSimilarExpByActivity(criteria),
              this.participants$,
              of(criteria)
            ])
          )
        )
        .subscribe(([experiences, participants, criteria]) => {
          const period = new Period({
            startDate: criteria.startDate,
            endDate: criteria.endDate
          });
          this.experiences = experiences.map((experience) => {
            return {
              id: experience.id,
              title: experience.name,
              background: experience.pictures[0],
              description: experience.description,
              link: `/station/${UrlUtils.encodeToURL(criteria.stationName)}/fiche-activite`,
              params: { experienceId: experience.id },
              selected: false,
              price: PackageUtils.minPriceString(
                experience.packagesByPeriod(period),
                participants
              )
            } as CardProductPresentation;
          });
        })
    );

    // Upsell packages
    this.register(
      this.criteria$
        .pipe(
          filter((criteria) => criteria.isValid),
          switchMap((criteria) => {
            criteria.type = ExperienceCategoryEnum.SKIPASS;
            return combineLatest([
              this.experienceService.getSimilarExpByActivity(criteria),
              this.participants$,
              of(criteria)
            ]);
          })
        )
        .subscribe(([experiences, participants, criteria]) => {
          const period = new Period({
            startDate: criteria.startDate,
            endDate: criteria.endDate
          });
          this.packages = experiences.map((experience) => {
            return {
              id: experience.id,
              title: experience.name,
              background: experience.pictures[0],
              description: experience.description,
              link: `/station/${UrlUtils.encodeToURL(criteria.stationName)}/fiche-activite`,
              params: { experienceId: experience.id },
              selected: false,
              price: PackageUtils.minPriceString(
                experience.packagesByPeriod(period),
                participants
              )
            } as CardProductPresentation;
          });
        })
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(new SetEstablishment(null));
  }

  getFeaturesRoom(room: Room): Feature[] {
    return AccommodationUtils.getFeaturesRoom(room);
  }

  getFeaturesEstablishment(establishment: Establishment): Feature[] {
    return AccommodationUtils.getFeaturesEstablishment(establishment);
  }

  addToCart(room: Room, establishment: Establishment, proposal: RoomProposal) {
    this.loading = true;

    const itemAccommodation: ItemAccommodation = new ItemAccommodation({
      establishment: new Establishment({
        id: establishment.id,
        partnerCode: establishment.partnerCode
      }),
      room: new Room({
        codeRoom: room.codeRoom,
        partnerCode: room.partnerCode,
        pictures: room.pictures
      }),
      session: new Session({
        id: this.store.selectSnapshot(FiltersState.sessionId)
      }),
      partner: new AccommodationPartner({
        partnerCode: room.partnerCode
      }),
      price: proposal.price,
      publicPrice: proposal.publicPrice,
      baseProductLabel: proposal.baseProductLabel,
      baseProductCode: proposal.productCode,
      payed: false,
      history: false,
      origin:
        room.partnerCode === Origin.MAEVA.toLowerCase()
          ? Origin.MAEVA
          : Origin.RESALYS,
      participants: proposal.participants,
      startDate: this.store.selectSnapshot(FiltersState.startDate),
      endDate: this.store.selectSnapshot(FiltersState.endDate),
      resort: this.store.selectSnapshot(FiltersState.resort)?.name,
      selectForm: undefined
    });

    this.store
      .dispatch(new AddAccommodationToCart(itemAccommodation))
      .pipe(
        catchError((err) => {
          if (err.status === 406) {
            this.error = `Ce logement n'est plus disponible ...`;
          } else {
            this.error = `Une erreur s'est produite, veuillez réessayer plus tard ...`;
          }
          this.alert();
          this.loading = false;
          return err;
        })
      )
      .subscribe(() => {
        this.showUpsell = true;
        this.store.dispatch(
          new TriggerAlert(
            new Alert({
              level: 'success',
              timeout: 5000,
              message: `Logement ajouté au panier, n'hésitez pas à faire le plein d'activités !`
            })
          )
        );
        this.loading = false;
      });
  }

  alert() {
    this.showAlert = true;

    setTimeout(() => {
      this.showAlert = false;
      this.success = '';
      this.error = '';
    }, 5000);
  }

  proposalsChange(participantsUuid: string[], room: Room, criteria: Criteria) {
    if (!participantsUuid.length || participantsUuid.length < room.minPeople) {
      return;
    }

    this.roomQuery = room;
    room.proposals = [];
    this.clickDebounce.next({
      participantsUuid,
      room,
      criteria
    });
  }

  getRoomTags(room: Room): Tag[] {
    const tags = [];
    if (room.surface > 0) {
      tags.push({ icon: 'surface', label: `${room.surface} m²` });
    }
    if (room.maxPeople > 0) {
      tags.push({ icon: 'group', label: `${room.maxPeople}` });
    }
    return tags;
  }

  scrollToPrice() {
    document.getElementById('pricing')?.scrollIntoView({
      behavior: 'smooth'
    });
  }

  openCart() {
    this.store.dispatch(new ChangeCartDrawerState(true));
  }

  goToWithParams(url: string) {
    this.router.navigate([url], { queryParamsHandling: 'merge' });
  }

  getSizeGrid(width: number | null) {
    const widthValue = width ?? window.innerWidth;

    if (widthValue >= WindowResource.WIDTH_MAX_DESKTOP) {
      return 5;
    }

    if (widthValue >= WindowResource.WIDTH_MAX_TABLET) {
      return 3;
    }

    return 1;
  }

  getPictures(pictures: string[], pictures2: string[]) {
    return [...pictures, ...pictures2];
  }

  isDescriptionDifferent(): boolean {
    return this.establishment.description !== this.room?.description;
  }

  openPopup(slide: number) {
    this.roomPictures = this.getPictures(
      this.establishment.pictures,
      this.room?.pictures || []
    );
    this.currentSlide = slide;
  }

  getLinkPackages() {
    if (!this.breadcrumbResort?.label) {
      return '';
    }

    return [
      `/station/${UrlUtils.encodeToURL(this.breadcrumbResort.label)}/forfaits`
    ];
  }

  getLinkActivities() {
    if (!this.breadcrumbResort?.label) {
      return ['/'];
    }

    return [
      `/station/${UrlUtils.encodeToURL(this.breadcrumbResort.label)}/activites`
    ];
  }

  protected boundsChangedAction(): void {}

  private mapRoomsCarousel() {
    if (this.rooms?.length) {
      this.roomsCarousel = this.rooms.map((room) => {
        return {
          id: room.codeRoom,
          background: room.pictures[0],
          params: { roomCode: room.codeRoom },
          link: `/station/${UrlUtils.encodeToURL(this.breadcrumbResort.label)}/logement`,
          tags: this.getRoomTags(room),
          title: room.name,
          description: room.description,
          price: room.proposals?.[0]?.price || 0,
          selected: false,
          region: this.establishment.address
        } as CardProductPresentation;
      });
    }
  }

  private updateTitleAndMetaDescription(
    establishment: Establishment,
    room: Room | undefined
  ) {
    const title = `${establishment.name} ${room?.name ?? ''} · VeryMountain`;
    const description = `${establishment.description}`;
    const url = `station/${UrlUtils.encodeToURL(this.breadcrumbResort.label)}/logement`;

    this.metaDescriptionService.updateMeta(title, description, url);
  }
}
